import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Row, Col, Button } from "react-bootstrap"
import { Section, Link } from "../../UI/Common"

import {
  sectionCls,
  imagesWrapperCls,
  titleLine1Cls,
  titleLine2Cls,
  doubleLineTitleCls,
  foreImageWrapperCls,
  bgImageCls,
  textBoxClass,
  linkCls
} from "./TextBoxAndLargeRightImg.module.scss"

const TextBoxAndLargeRightImg = ({ textBox, imageBox, config }) => {
  const { padding } = config || {}
  const { sectionHeading, description, button } = textBox
  const { backgroundImage, foregroundImage, largeTitle: title } = imageBox

  let titleLine1Styles = titleLine1Cls

  if (Object.keys(title).length > 1) {
    titleLine1Styles = `${titleLine1Cls} ${doubleLineTitleCls}`
  }


  return (
    <Section padding={padding}>
      <div className={`${sectionCls} padded-component`}>
        <Row>
          <Col
            xs={{ span: 12, order: "first" }}
            xl={{ span: 7, order: "last" }}>
            <div className={imagesWrapperCls}>
              {backgroundImage && (
                <GatsbyImage
                  className={bgImageCls}
                  alt={backgroundImage.altText}
                  image={getImage(backgroundImage.localFile)}
                />
              )}
              <div className="d-none d-lg-block">
                <div className={titleLine1Styles}>{title.lineOne}</div>
                {title.lineTwo && (
                  <div className={titleLine2Cls}>{title.lineTwo}</div>
                )}
              </div>
              {foregroundImage && (
                <GatsbyImage
                  className={foreImageWrapperCls}
                  style={{ position: "absolute" }}
                  objectFit="scale-down"
                  alt={backgroundImage.altText}
                  image={getImage(foregroundImage.localFile)}
                />
              )}
            </div>
          </Col>
          <Col
            xs={{ span: 12, order: "last" }}
            xl={{ span: 5, order: "first" }}>
            <Row className="h-100">
              <Col xs={12} xl={0} />
              <Col xs={12} xl={10} className="h-100 offset-xxl-1 offset-xxl-2 col-xxl-8">
                <div className={textBoxClass}>
                  <h1 className="py-3">{sectionHeading}</h1>
                  <div className="mt-3 mb-5 fs-xl-4">{description}</div>
                  <Link className={linkCls} link={button.url}>
                    <Button size="lg">{button.title}</Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Section>
  )
}

export default TextBoxAndLargeRightImg
