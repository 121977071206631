import { graphql } from "gatsby"
import React from "react"

import TextBoxAndLargeRightImgComponent from "./TextBoxAndLargeRightImg"

export const fragment = graphql`
  fragment TextBoxAndLargeRightImgFragment on WpPage_Flexlayouts_FlexibleLayouts_TextBoxAndLargeRightImg {
    textBox {
      sectionHeading
      description
      button {
        title
        url
        target
      }
    }
    imageBox {
      largeTitle {
        lineOne
        lineTwo
      }
      backgroundImage {
        altText
        sourceUrl
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP, AVIF]
              width: 1194
              height: 617
              quality: 75
              placeholder: NONE
            )
          }
        }
      }
      foregroundImage {
        altText
        sourceUrl
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP, AVIF]
              height: 639
              quality: 75
              placeholder: NONE
            )
          }
        }
      }
    }
    config {
      padding {
        top {
          topMobile
          topTablet
          topDesktop
        }
        bottom {
          bottomMobile
          bottomTablet
          bottomDesktop
        }
      }
    }
  }
`

export const ACFTextBoxAndLargeRightImg = ({
  textBox,
  imageBox,
  config,
}) => (
  <TextBoxAndLargeRightImgComponent
    textBox={textBox}
    imageBox={imageBox}
    config={config}
  />
)
