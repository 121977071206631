// extracted by mini-css-extract-plugin
export var bgGrey100 = "TextBoxAndLargeRightImg-module--bg-grey-100--9e6e7";
export var bgGrey150 = "TextBoxAndLargeRightImg-module--bg-grey-150--93c23";
export var bgGrey200 = "TextBoxAndLargeRightImg-module--bg-grey-200--d5021";
export var bgGrey300 = "TextBoxAndLargeRightImg-module--bg-grey-300--7c1ff";
export var bgGrey400 = "TextBoxAndLargeRightImg-module--bg-grey-400--28730";
export var bgGrey500 = "TextBoxAndLargeRightImg-module--bg-grey-500--fdddc";
export var bgGrey600 = "TextBoxAndLargeRightImg-module--bg-grey-600--0dd99";
export var bgGrey700 = "TextBoxAndLargeRightImg-module--bg-grey-700--714ac";
export var bgGrey800 = "TextBoxAndLargeRightImg-module--bg-grey-800--d4a4b";
export var bgGrey900 = "TextBoxAndLargeRightImg-module--bg-grey-900--b0239";
export var bgImageCls = "TextBoxAndLargeRightImg-module--bgImageCls--e7607";
export var doubleLineTitleCls = "TextBoxAndLargeRightImg-module--doubleLineTitleCls--496f6";
export var foreImageWrapperCls = "TextBoxAndLargeRightImg-module--foreImageWrapperCls--3beec";
export var imagesWrapperCls = "TextBoxAndLargeRightImg-module--imagesWrapperCls--cb889";
export var linkCls = "TextBoxAndLargeRightImg-module--linkCls--accb0";
export var sectionCls = "TextBoxAndLargeRightImg-module--sectionCls--7a557";
export var textBoxClass = "TextBoxAndLargeRightImg-module--textBoxClass--830e7";
export var textGrey100 = "TextBoxAndLargeRightImg-module--text-grey-100--29c80";
export var textGrey150 = "TextBoxAndLargeRightImg-module--text-grey-150--25459";
export var textGrey200 = "TextBoxAndLargeRightImg-module--text-grey-200--1f09f";
export var textGrey300 = "TextBoxAndLargeRightImg-module--text-grey-300--fbe0c";
export var textGrey400 = "TextBoxAndLargeRightImg-module--text-grey-400--a8579";
export var textGrey500 = "TextBoxAndLargeRightImg-module--text-grey-500--abc14";
export var textGrey600 = "TextBoxAndLargeRightImg-module--text-grey-600--84fef";
export var textGrey700 = "TextBoxAndLargeRightImg-module--text-grey-700--485a9";
export var textGrey800 = "TextBoxAndLargeRightImg-module--text-grey-800--5246d";
export var textGrey900 = "TextBoxAndLargeRightImg-module--text-grey-900--20d70";
export var titleLine1Cls = "TextBoxAndLargeRightImg-module--titleLine1Cls--a09cc";
export var titleLine2Cls = "TextBoxAndLargeRightImg-module--titleLine2Cls--6d2e4";